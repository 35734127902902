




































































import Vue from 'vue';
import Component from 'vue-class-component';
import {ErrorAPI, GatewayAPI, MerchantAPI} from '@/api';
import sodium from 'libsodium-wrappers';

interface PaymentBench {
  order_id: string;
  amount: number;
  currency: string;
  description: string;
  redirect: boolean;
  new_payment_type: boolean;
  back_url: string;
}

@Component
export default class ApplicationBenchForm extends Vue {
  private paymentBench: PaymentBench = {
    order_id: Date.now().toString(),
    amount: 100,
    currency: 'KZT',
    description: 'Test payment',
    redirect: false,
    new_payment_type: false,
    back_url: 'https://' + (location.href.includes('preprod') ? 'preprod-' : (location.href.includes('dev') ? 'dev-' : '')) + 'merchant.sbp.kz/payments',
  };

  private publicKey: string = '';
  private privateKey: string = '';
  private isLoading: boolean = false;
  private errors = new ErrorAPI();

  public async mounted() {
    const app = await MerchantAPI.applicationFind(this.$route.params.appKey);
    this.publicKey = app.public_key;
  }

  private valueRegex(event: any, regex: string) {
    event = event ?? window.event;
    const re = new RegExp(regex);
    const clipboardData = event.clipboardData || window.clipboardData;
    let str: string;

    if (clipboardData !== undefined) {
      str = clipboardData.getData('Text');
    } else {
      str = String.fromCharCode(event.which ?? event.keyCode);
    }

    if (!re.test(str)) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  private async onPay() {
    this.isLoading = true;
    this.errors.clear();

    if (!this.validate()) {
      return false;
    }

    try {
      const body = JSON.stringify(this.paymentBench);

      fetch(GatewayAPI.url('payments'), {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          'Auth-Identifier': this.$route.params.appKey,
          'X-Auth-Checksum': this.encryptWithSodium(body),
        },
        redirect: 'manual',
        referrerPolicy: 'no-referrer',
        body,
      }).then(async (created: Response) => {
        const jsonBody = await created.json();
        switch(created.status) {
          case 200:
          case 201:
            window.open(jsonBody.url, '_blank');
            break;
          case 401:
            this.$buefy.toast.open({
              message: jsonBody.error,
              position: 'is-bottom',
              type: 'is-danger',
            });
            break;
          case 422:
            this.errors.put(jsonBody ?? new Map());
            break;
          default:
            this.$buefy.toast.open({
              message: created.statusText || created.toString(),
              position: 'is-bottom',
              type: 'is-danger',
            });
        }
      });
    } catch (err) {
      this.$buefy.toast.open({
        message: err.response?.data.error || err.toString(),
        position: 'is-bottom',
        type: 'is-danger',
      });
    } finally {
      this.isLoading = false;
    }
  }

  /**
   * Encrypt body with private key
   *
   * @param body
   * @private
   */
  private encryptWithSodium(body: string): string {
    try {
      return sodium.to_base64(
          sodium.crypto_sign_detached(body, sodium.from_base64(this.privateKey, sodium.base64_variants.ORIGINAL)),
          sodium.base64_variants.ORIGINAL,
      );
    } catch (err) {
      throw new Error(this.$t('PrivateKeyError').toString());
    }
  }

  private validate() {
    const form = this.$refs['vuejs-form-data'] as HTMLFormElement;
    if (form.checkValidity && !form.checkValidity()) {
      const elements = [...form.elements] as HTMLElement[];
      elements.forEach((e) => e.focus());
      // form.reportValidity();
      return false;
    }

    return true;
  }
}
